// write home page here

import { useNavigate, useParams } from 'react-router-dom';
import { WorkoutLogService } from '../services/WorkoutLogService';
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation, Pagination } from 'swiper/modules';
import parse from 'html-react-parser';

const Card = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    margin: '15px',
    background: '#fff',
    borderRadius: '5px',
});

const CardHeader = styled('div')({
    display: 'flex',
    gap: '10px',
    padding: '5px',
    alignItems: 'center'
});

const CardAvatar = styled('img')({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
});

const CardTitle = styled('h1')({
    margin: '0',
    fontSize: '14px',
});

const CardMedia = styled('div')({
    width: '100%',
    height: 'calc(100vw - 30px)',
    background: 'lightgray',
});


const CardNote = styled('div')({
    width: '100%',
    padding: '15px'
});

const MediaImage = styled('img')({
    width: '100%',
    height: '100%'
});
const MediaVideo = styled('video')({
    width: '100%',
    height: '100%'
});

const CardResults = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',

});


const CardResult = styled('div')({
    flex: '0 0 calc(33.333% - 10px)',
    marginBottom: '10px',
    maxWidth: 'calc(33.333% - 10px)',
    overflow: 'hidden',
    padding: '15px 0',
});

const ResultAttribute = styled('div')({
    fontSize: '12px',
    textAlign: 'center',
    width: '100%',
    color: '#666',
});

const ResultValue = styled('div')({
    fontSize: '20px',
    textAlign: 'center',
    width: '100%',
    fontWeight: 'bold',
    marginBottom: '5px',
});

const sanitizeHtml = (htmlString: string) => {
    // This is a basic example where you might remove or replace dangerous tags or attributes
    return htmlString.replace(/<script[^>]*?>.*?<\/script>/gi, '')
                     .replace(/<style[^>]*?>.*?<\/style>/gi, '');
  };

const WorkoutLog = () => {
    const navigate = useNavigate();
    const service = WorkoutLogService.getInstance();
    const [loading, setIsLoading] = React.useState(false);
    const { id } = useParams();
    const [workoutLog, setWorkoutLog] = React.useState<any>();
    const [note, setNote] = React.useState<any>();
    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className:string) {
            const mediaItem = workoutLog.media[index];
            let imageUrl = mediaItem.url
            if (mediaItem.mime_type.includes('video')) {    
                const fileName = mediaItem.url.split('/').pop();
                imageUrl = 'https://ik.imagekit.io/battlefit/timelapse/tr:di-video-poster_xQuz8_i1t.png/'+fileName.replace('mp4', 'jpg');
            }
            return `<img src="${imageUrl}" class="${className}" style="border: 3px solid #4462a9;width: 45px; height: 45px; margin-right: 5px; box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; border-radius: 10px;" />`;
        },
    };


    useEffect(() => {
        console.log('yeapp')
        if (workoutLog && workoutLog.note && workoutLog.note !== null) {
            const sanitizedHtml = sanitizeHtml(workoutLog.note);
            console.log(sanitizedHtml);
            setNote(parse(sanitizedHtml));
        }
    }, [workoutLog]);

    useEffect(() => {
        setIsLoading(true);
        if (id) {
            service.get(id).then((data: any) => {
                console.log(data);
                setWorkoutLog(data);

                if (data.note) {
                    const sanitizedHtml = sanitizeHtml(data.note);
                    console.log(sanitizedHtml);
                    setNote(parse(sanitizedHtml));
                }
            })
        }
    }, []);

    
    
    return (
        <div>
            {workoutLog && 
                <Card>
                    <CardHeader>
                        <CardAvatar src={"https://ik.imagekit.io/battlefit/avatars/tr:di-default-profile_03ewOi9XW.jpg,n-avatar/"+workoutLog.owner+".png"} alt={workoutLog.firstName} ></CardAvatar>
                        <CardTitle>{workoutLog.firstName} {workoutLog.lastName} | {workoutLog.workoutName} </CardTitle>
                    </CardHeader>
                    
                    {workoutLog.media && workoutLog.media.length > 0 &&
                    <CardMedia>
                        
                        <Swiper
                            navigation={true}
                            
                          pagination={pagination}
                          modules={[Pagination, Navigation]}
                        spaceBetween={50}
                        slidesPerView={1}
                        >
                        {workoutLog.media.map((media: any, index: number) => (
                            <>
                            <SwiperSlide key={index}>
                                {media.mime_type.includes('image') &&
                                    <MediaImage src={media.url} /> 
                                }
                                {media.mime_type.includes('video') &&
                                    <MediaVideo src={media.url} /> 
                                }
                            </SwiperSlide>
                            </>
                        ))}
                        </Swiper>
                    </CardMedia>
                    }

                    <CardResults>
                        {workoutLog.results.map((result: any, index: number) => (
                        <CardResult>
                            <ResultValue>{result.formatted}</ResultValue>

                            <ResultAttribute>{result.measurement}</ResultAttribute>
                        </CardResult>
                        ))}
                    </CardResults>

                    {workoutLog.note &&
                    <CardNote>
                        {note}
                    </CardNote>
                    }
                </Card>
            }
        </div>
    );
}
export default WorkoutLog;