import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LeaderboardService } from '../services/LeaderboardService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './ListProfileComponent.scss';
import { Avatar } from '@mui/material';
import PipeService from '../services/PipeService';

const ListProfileComponent = ({ profile, position }) => {
    const { avatar } = PipeService.getInstance();

    return (
        <div className='list-profile-item'>
            <Card variant="outlined">
                <CardContent className='card-wrapper'>
                    <Avatar alt={profile.firstName} src={avatar(profile.userId)} />
                    <div className='card-content'>
                        <Typography variant="p" component="div">
                            {position+1} {profile.firstName} {profile.lastName && profile.lastName.charAt(0)}.
                        </Typography>
                    </div>
                    <div className="card-value">
                        <Typography variant="p" component="div">
                            {profile.formatted}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
}
export default ListProfileComponent;