import { Workout } from '../interfaces/workout';
import { ApiService } from './ApiService'; // Adjust the import path as needed

export class WorkoutLogService {
  private static instance: WorkoutLogService;
  private api: ApiService;

  private constructor() {
    this.api = ApiService.getInstance();
  }

  public static getInstance(): WorkoutLogService {
    if (!WorkoutLogService.instance) {
      WorkoutLogService.instance = new WorkoutLogService();
    }
    return WorkoutLogService.instance;
  }

  async get(uid: string) {
    return await this.api.get<Workout>(`workout-log/${uid}/preview`);
  }

  
}